import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import history1 from '../../assets/images/features1.png'
import history2 from '../../assets/images/features3.png'

const OurHistoryContentTwo = () => {
    return (
        <div className="history-area ptb-50">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="about" /> 
                        Our Solutions
                    </span>
                    <h2>Plan Your Path. Find Your Way.</h2>
                    <p>Collaborate on goals. Manage performance. Stay closely connected to your students.</p>
                </div>

                <ol className="timeline history-timeline history-timeline-style-two">
                    <li className="timeline-block">
                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={history1} alt="about"  className="imgborder" />
                                    </div>
                                </div>

                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Percorso Portfolio</h3>
                                        <p>Percorso Portfolio provides a distance learning platform that keeps field education activities aligned with educational goals and motivated by performance… in an easy-to-manage process.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    <li className="timeline-block">

                        <div className="timeline-content">
                            <div className="row align-items-center">
                                <div className="col-lg-5 col-md-12">
                                    <div className="image">
                                        <img src={history2} alt="about" className="imgborder" />
                                    </div>
                                </div>

                                <div className="col-lg-7 col-md-12">
                                    <div className="content">
                                        <h3>Percorso Preaching</h3>
                                        <p>Percorso Preaching allows preachers, lectors, and rhetoricians to record their presentations and share some or all of them for feedback and critique.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>

                    
                </ol>
            </div>
        </div>
    )
}

export default OurHistoryContentTwo